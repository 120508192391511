@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap");

.notyett-container {
  max-width: 1258px !important;
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
