.message-wrapper {
  margin-top: 4em;
}

p.big-message {
  font-family: Inter;
  font-size: 4em;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 24px;
}

p.small-message {
  font-family: Inter;
  font-size: 16px;
  margin-bottom: 3em;
}

img.intro-body-image {
  bottom: -70px;
  position: absolute;
  width: 650px;
  z-index: -999;
  right: 5%;
}

img.intro-top-image {
  width: 350px;
  right: 120px;
  bottom: 245px;
  z-index: 99;
}
.anime-div {
  padding-left: 100px;
  padding-top: 60px;
}

/* Responsive design */

@media (max-width: 1250px) {
  p.big-message {
    font-size: 3em;
  }
}

@media (max-width: 1000px) {
  p.big-message {
    font-size: 2.3em;
  }
  img.intro-top-image {
    width: 200px;
  }
  img.intro-body-image {
    width: 500px;
    bottom: 0px;
  }
  .anime-div {
    padding-top: 125px;
  }
  button.intro-cta {
    margin-bottom: 24px;
  }
}

@media (max-width: 800px) {
  .right-intro {
    display: none;
  }
}
