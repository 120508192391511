.card {
  border-radius: 40px;
  box-shadow: 0 0 1.5em #b7b7b79c;
  border: 0;
  padding: 24px;
}

.card img.icon {
  width: 150px;
  margin: 30px auto;
}

.card h1.title {
  font-weight: 700;
  line-height: 35px;
  padding: 0 10px;
  font-family: "Inter";
  font-size: 2em;
}

.card p.description {
  padding: 0 10px;
  font-family: "Inter";
  font-size: 0.9em;
}
