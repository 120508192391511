.top-wrapper {
  padding-top: 5em;
}
.big-text {
  width: 75%;
  font-family: Inter;
  font-size: 3.5em;
  font-weight: 700;
  line-height: 1.2em;
}

.small-text {
  width: 65%;
  font-family: "Inter";
  color: #696969;
}

.illustrations-wrappper {
  background: #6bff926b;
  position: relative;
  height: 257px;
  margin-top: 100px;
}

img.ladlord-man,
img.ladlord-lampe,
img.ladlord-women,
img.tenant-women,
img.tenant-man {
  position: absolute;
}

img.ladlord-women {
  width: 300px;
  right: 72%;
  bottom: -65px;
}

img.ladlord-lampe {
  left: 36%;
  width: 90px;
  top: 45px;
}

img.ladlord-man {
  right: 15%;
  bottom: 0;
}

img.tenant-man {
  right: 15%;
  bottom: 125px;
  width: 400px;
}

img.tenant-women {
  top: -95px;
  width: 550px;
  left: 50px;
}

/* Card Wrapper */
.cards-wrapper {
  margin-top: 100px;
}

.card-line {
  height: 100px;
  background: #c1ffd1;
  position: relative;
  top: -50px;
  z-index: -999;
}

h1.contact-tilie {
  font-family: "Inter";
  font-weight: 700;
  font-size: 3em;
  width: 45%;
}

.contact-wrapper {
  margin-top: 40px;
  width: 50%;
}

.top-input {
  margin-bottom: 30px;
}

.contact-wrapper input {
  font-family: Inter;
}

.contact-form {
  margin-bottom: 6em;
  position: relative;
}

.submit-button {
  height: 100%;
}

img.contact-icon {
  position: absolute;
  right: 15%;
  top: 0;
  width: 400px;
}

footer.foot-wrapper {
  background: black;
  padding: 50px;
}

p.footer-text {
  color: #fff;
  font-family: "Inter";
  font-weight: 600;
  font-size: 2em;
  line-height: 1.3em;
  padding: 0;
  margin: 0;
}

.footer-logo {
  text-align: right;
  width: 100px;
  margin: auto;
}

@media (max-width: 850px) {
  p.big-text {
    font-size: 2.5em;
  }
  p.small-text {
    font-size: 12px;
  }
  .card h1.title {
    font-size: 1.5em;
    line-height: 25px;
  }
  .card p.description {
    font-size: 0.7em;
  }
  h1.contact-tilie {
    font-size: 2em;
  }
  .contact-wrapper {
    width: 65%;
  }
  img.contact-icon {
    right: 2%;
  }
}

@media (max-width: 700px) {
  img.contact-icon {
    display: none;
  }

  .contact-wrapper {
    width: 100%;
  }
  img.ladlord-man {
    width: 145px;
  }
  img.ladlord-women {
    width: 243px;
    right: 62%;
    bottom: 15px;
  }
  .card-element {
    margin-bottom: 30px;
  }
}

@media (max-width: 550px) {
  h1.contact-tilie {
    width: 95%;
  }
  p.big-text {
    width: 100%;
  }
  p.small-text {
    width: 100%;
  }
  p.footer-text {
    font-size: 1em;
  }
}

@media (max-width: 350px) {
  .illustrations-wrappper {
    display: none;
  }
  .contact-form .col {
    width: 100%;
    flex-basis: unset;
    margin-bottom: 30px;
  }
}
