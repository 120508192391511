header.header.container {
  max-width: 1258px;
  border-bottom: 1px solid #e3e3e3;
  padding: 20px 15px;
}

.menu-wrapper ul {
  margin: 20px 0;
  padding: 0;
  float: right;
}

.menu-wrapper ul li {
  display: inline-block;
}

.menu-wrapper ul li a {
  margin: 24px;
  font-family: Inter;
  color: black;
  font-weight: 600;
}

.menu-wrapper ul li a:hover {
  text-decoration: none;
}

img.logo {
  margin: 8px 0;
  width: 90px;
}
